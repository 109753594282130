import { acceptHMRUpdate, defineStore } from 'pinia'
import { ref } from 'vue'
import { PostProjectPlotViewPayload, useProjectApiStore } from '@aedifion.io/pinia-aedifion-api-stores'
import { reportError } from '@/utils/helpers/errors'

export const usePlotViewsInProjectStore = defineStore('plotViewsInProject', () => {
  const projectApiStore = useProjectApiStore()
  const loading = ref(false)

  async function postPlotView (payload: PostProjectPlotViewPayload): Promise<string|undefined> {
    loading.value = true

    try {
      const result = await projectApiStore.postProjectPlotView(payload)
      return result.id
    } catch (error) {
      reportError(error)
      return undefined
    } finally {
      loading.value = false
    }
  }

  return {
    postPlotView,
    loading,
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(usePlotViewsInProjectStore, import.meta.hot))
}
